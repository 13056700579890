import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import PageableService from 'app/modules/common/framework/pagination/pageable-service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {
  EmailHistory,
  EmailHistoryContactDetails,
  EmailHistoryContactGroupViewTO,
  EmailHistoryContactTotals,
  EmailHistoryCreateInputDTO,
  EmailHistoryView,
} from '../model/mailing.model';

/**
 * Service to interact with the server for email hitory operations.
 */
@Injectable({
  providedIn: 'root',
})
export class EmailHistoryService implements PageableService<EmailHistoryView> {
  constructor(private http: HttpClient) {}

  /**
   * Create a new email history entry.
   *
   * @param data the email data
   * @param attachments list of files to attach to the email
   * @returns the created email history entity wrapped in an observable
   */
  create(data: EmailHistoryCreateInputDTO, attachments: File[] = []): Observable<EmailHistory> {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }

    for (const attachment of attachments) {
      formData.append('attachments', attachment);
    }

    return this.http.post<EmailHistory>(`${environment.apiUrl}/email-history`, formData);
  }

  /**
   * Upload a file to be attached to an email as a link.
   *
   * @param file the file to be uploaded
   * @returns the download url wrapped in an observable
   */
  uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<string>(`${environment.apiUrl}/email-history/file`, formData);
  }

  /**
   * Get a single page of email history.
   *
   * @param page the page number
   * @param size the size of the page
   * @param sort the column and direction to sort by
   * @param filter the filters to apply to the query
   * @returns the page found wrapped in an observable
   */
  getPage(page: number, size: number, sort: string | string[], filter: string): Observable<Pageable<EmailHistoryView>> {
    // Notice, we encode the parameter manually instead os passing it as HttpParams attribute, to avoid angular encoding that removes the '+'.

    return this.http.get<Pageable<EmailHistoryView>>(
      `${environment.apiUrl}/email-history?page=${page}&size=${size}&filter=${encodeURIComponent(filter)}&sort=${sort}`
    );
  }

  /**
   * Get a single email history entry from its id.
   *
   * @param idtEmailHistory the id of the email history entry
   * @returns the email history data found
   */
  getOne(idtEmailHistory: number): Observable<EmailHistoryView> {
    return this.http.get<EmailHistoryView>(`${environment.apiUrl}/email-history/${idtEmailHistory}`);
  }

  /**
   * Gets a page of contacts that will receive an email.
   *
   * @param page the page number
   * @param size the size of the page
   * @param sort the column and direction to sort by
   * @param filter the filters to apply to the query
   * @returns the page found wrapped in an observable
   */
  getContactPage(page: number, size: number, sort: string | string[], filter: string): Observable<Pageable<EmailHistoryContactGroupViewTO>> {
    return this.http.get<Pageable<EmailHistoryContactGroupViewTO>>(
      `${environment.apiUrl}/email-history/contact?page=${page}&size=${size}&filter=${encodeURIComponent(filter)}&sort=${sort}`
    );
  }

  /**
   * Export a campaing contacts messages status.
   *
   * @param idtEmailHistory the campaing id
   */
  exportCampainContacts(idtEmailHistory: number): Observable<Blob> {
    const filter = new DataFilter().equals('idtEmailHistory', idtEmailHistory, 'long').encodeURIComponent();

    return this.http.get<Blob>(`${environment.apiUrl}/email-history/contact/export?filter=${filter}`, {
      responseType: 'blob' as 'json',
    });
  }

  /**
   * Gets a paged datasource object of contacts.
   *
   * @param size the size of the pages
   * @param sort the column and direction to sort by
   * @param filter the filters to apply to the query
   * @returns a paged datasource that will load the next page on scroll
   */
  getContactDatasource(size: number, sort: string | string[] = '', filter: string = ''): PageableDataSource<EmailHistoryContactGroupViewTO> {
    /* eslint-disable @typescript-eslint/no-this-alias */
    const self = this;

    return new (class extends PageableDataSource<EmailHistoryContactGroupViewTO> {
      fetch(page: number, limit: number): Observable<Pageable<EmailHistoryContactGroupViewTO>> {
        return self.getContactPage(page, limit, sort, filter);
      }
    })(size);
  }

  /**
   * Gets a paged datasource object of email draft and sent messages, filtered by email history entry.
   *
   * @param idtEmailHistory the id of the email history to filter for
   * @param size the size of the page
   * @param sort the column and direction to sort by
   * @returns a paged datasource that will load the next page on scroll
   */
  getContactDatasourceByIdtEmailHistory(
    idtEmailHistory: number,
    size = 40,
    sort = 'contactName'
  ): PageableDataSource<EmailHistoryContactGroupViewTO> {
    const filter = `idtEmailHistory|eq|${idtEmailHistory}|long`;

    return this.getContactDatasource(size, sort, filter);
  }

  /**
   * Get the total messages sent and to be sent.
   *
   * @param idtEmailHistory the email history id to filter for
   * @returns an object with the total values, wrapped in an observable
   */
  getContactTotalsByIdtEmailHistory(idtEmailHistory: number): Observable<EmailHistoryContactTotals> {
    const filter = `idtEmailHistory|eq|${idtEmailHistory}|long`;

    return this.http.get<EmailHistoryContactTotals>(`${environment.apiUrl}/email-history/contact/totals?filter=${encodeURIComponent(filter)}`);
  }

  /**
   * Gets a datasource for email history.
   *
   * @param idtEmailGroup the email group
   * @param size the size of the page
   * @returns the datasource that fetches the next page on scroll
   */
  getHistory(idtEmailGroup: number, size = 40): PageableDataSource<EmailHistoryView> {
    const self = this;

    return new (class extends PageableDataSource<EmailHistoryView> {
      fetch(page: number, limit: number): Observable<Pageable<EmailHistoryView>> {
        const filter = new DataFilter().equals('idtEmailGroup', idtEmailGroup, 'long');
        return self.getPage(page, limit, '', filter.encode());
      }
    })(size);
  }

  /**
   * Get the details of an email draft or sent.
   * .
   * @param idtEmailHistoryContact the entry id
   * @returns the details of the message wrapped in an observable
   */
  getContactDetails(idtEmailHistoryContact: number): Observable<EmailHistoryContactDetails> {
    return this.http.get<EmailHistoryContactDetails>(`${environment.apiUrl}/email-history/contact/${idtEmailHistoryContact}`);
  }

  /**
   * Sends the emails messages of the provided email history id.
   *
   * @param idtEmailHistory the email history id
   * @param idsToIgnore the list of email history contact ids to not send
   * @returns an observable that resolves to void
   */
  send(idtEmailHistory: number, idsToIgnore: number[]): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/email-history/${idtEmailHistory}/send`, idsToIgnore);
  }

  /**
   * Deletes an email history entry.
   *
   * @param ids the ids of the email histories to delete
   * @returns an empty observable that completes after the request is processed
   */
  delete(ids: number[]): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/email-history`, {
      params: {
        ids: ids.map((e) => e.toString()),
      },
    });
  }

  /**
   * Trigger fetching email analytics.
   *
   * @returns an observable that resolves to void when the server responds
   */
  triggerAnalytics(): Observable<void> {
    return this.http.get<void>(`${environment.apiUrl}/email-history/trigger-analytics`);
  }

  /**
   * Trigger fetching email analytics.
   *
   * @returns an observable that resolves to void when the server responds
   */
  triggerCheckStatus(): Observable<void> {
    return this.http.get<void>(`${environment.apiUrl}/email-history/trigger-check-status`);
  }

  /**
   * Get the last message sent to the provided email.
   *
   * @param email the email address
   * @returns an observable that emits the last sent message data when the server responds
   */
  getLastMessage(email: string): Observable<EmailHistoryContactGroupViewTO> {
    return this.http.get<EmailHistoryContactGroupViewTO>(`${environment.apiUrl}/email-history/last`, {
      params: { email },
    });
  }
}
